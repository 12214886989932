// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";

// ng select
@import "~@ng-select/ng-select/themes/default.theme.css";

// If you want to add something do it here
@import "custom";

@import "swall-icon";

@import '@angular/material/prebuilt-themes/indigo-pink.css';

/* Importing Datepicker SCSS file. */
@import"ngx-bootstrap/datepicker/bs-datepicker";

.cdk-overlay-container{
  z-index:2000 !important; //higher then fixed header z-index so it comes above
}

.iti {
  width: 100%;
}
