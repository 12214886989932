@import "variables";

.btn {
  padding: 6px 20px;
  cursor: pointer;
  &.btn-warning {
    color: white;
  }

  &.btn-light-primary {
    background-color: lighten($primary, 40);
    color: $primary;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  &.btn-light-info {
    background-color: lighten($info, 40);
    color: $info;

    &:hover {
      background-color: $info;
      color: $white;
    }
  }

  &.btn-light-warning {
    background-color: lighten($warning, 40);
    color: $warning;

    &:hover {
      background-color: $warning;
      color: $white;
    }
  }

  &.btn-light-danger {
    background-color: lighten($danger, 35);
    color: $danger;

    &:hover {
      background-color: $danger;
      color: $white;
    }
  }

  &.btn-light-success {
    background-color: lighten($success, 40);
    color: $success;

    &:hover {
      background-color: $success;
      color: $white;
    }
  }

  &.btn-light-dark {
    background-color: lighten($dark, 40);
    color: $dark;

    &:hover {
      background-color: $dark;
      color: $white;
    }
  }

  &.btn-light-secondary {
    background-color: lighten($secondary, 40);
    color: $secondary;

    &:hover {
      background-color: $secondary;
      color: $white;
    }
  }

  &.btn-outline-warning {
    &:hover {
      color: white;
    }
  }
}

.button-blue {
  background: $primary-2;
  border: 2px solid $primary-2;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0;
  color: $white;
}

.button-blue.disabled {
  cursor: not-allowed;
  color: $white !important;
}

.button-blue:not(.disabled):hover {
  background: transparent;
  color: $primary-2;
  border: 2px solid $primary-2;
}

.back-btn {
  background: transparent;
  border: 2px solid $primary-2;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 16px/24px Open Sans;
  letter-spacing: 0;
  color: $primary-2;
}

.btn-outline-purple {
  font-size: 0.7rem;
  border: 1px solid $secondary-3;
  border-radius: 5px;
  text-align: center;
  background-color: $white;
  color: $secondary-3;
}

.btn-outline-purple:hover {
  color: $white;
  background-color: $secondary-3;
  border-color: $secondary-3;
}

.button-darkblue {
  background: #34495e;
  border-radius: 4px;
  color: $white;
}

.button-darkblue:hover {
  background: $white;
  border-color: #34495e;
  color: #34495e;
}

.btn-outline-blue {
  cursor: pointer;
  border: 2px solid #3498db;
  border-radius: 4px;
  color: #3498db;
}

.btn-outline-blue:hover {
  border-color: #3498db;
  background-color: #3498db;
  color: $white;
}

.button-green {
  background: $success;
  border-radius: 4px;
  color: $white;
}

.button-green:hover {
  background: $white;
  border-color: $success;
  color: $success;
}
