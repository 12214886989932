@import "variables";

.text-align-center {
  text-align: center !important;
}

.mlr-auto {
  margin: 0 auto;
}

.wid-100 {
  width: 98%;
}

.wid-90 {
  width: 88%;
}

.wid-70 {
  width: 71%;
}

.wid-100-dropdown {
  width: 98%;
  margin-bottom: 2%;
}

.wid-50 {
  width: 80%;
  margin-bottom: 2%;
}

.wid-20 {
  width: 20%;
}

.wid-5050 {
  width: 48%;
}
