@import "vendors/variables";

.card {
  box-shadow: 0 1px 15px $medium-gray;
  border: none;
  margin-bottom: 24px;

  .card-header {
    background-color: $dark-gray;
    font-weight: bold;
    color: white;

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
      color: white;
    }
  }
}

.new-card {
  width: 100%;
  background: $white 0 0 no-repeat padding-box;
  border-radius: 5px;
  //padding-bottom: 15px;
}

.new-card-content {
  //display: flex;
  margin: 15px 25px 15px 25px;
}
