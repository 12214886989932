@import "variables";

a.nav-link {
  font-family: "Open sans", serif;
}

html:not([dir=rtl]) .sidebar {
  margin-left: -225px;
}

.sidebar-fixed .sidebar,
.sidebar .sidebar-nav,
.sidebar .nav {
  width: 225px;
}

@media (min-width: 992px) {
  html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main, html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 225px;
  }
}

.sidebar .nav-title {
  font-family: "Open sans", serif;
  font-weight: bold !important;
  font-size: 0.8rem;
}

.sidebar .nav-link {
  padding: 0.7rem 0.3rem;
}
