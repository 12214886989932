// Variable overrides

// Gray colors:
$ultra-dark-gray: #2C3E50;
$dark-gray: #34495E;
$gray: #6E7878;
$color-gray-1: #95A5A6;
$medium-gray: #E4E4E4;
$light-gray: #F7F7F9;
$white: #FFFFFF;

// Primary colors:
$primary-1: #E74C3C;
$primary-2: #3498DB;
$primary-2-light: #f5fafd;
$color-red-1: #ec887e;

// Secondary colors:
$secondary-1: #F53D6B;
$secondary-2: #F1C40F;
$secondary-3: #9B59B6;
$secondary-4: #2ECC71;

// bootstrap theme
$success: $secondary-4;
$warning: $secondary-2;
$danger: $secondary-1;
$info: $primary-2;
$primary: $primary-1;
$dark: $dark-gray;
$secondary: $gray;

// Body variables
$body-bg: $light-gray;

// Navbar variables
$sidebar-bg: $white;
$sidebar-nav-title-color: $ultra-dark-gray;

$sidebar-nav-link-color: $dark-gray;
$sidebar-nav-link-bg: $white;
$sidebar-nav-link-icon-color: $dark-gray;

$sidebar-nav-link-active-color: $primary-2;
$sidebar-nav-link-active-icon-color: $primary-2;
$sidebar-nav-link-active-bg: $primary-2-light;

$sidebar-nav-link-hover-color: $primary-2;
$sidebar-nav-link-hover-icon-color: $primary-2;
$sidebar-nav-link-hover-bg: $primary-2-light;

// Breadcrumb variables
$breadcrumb-bg: $light-gray;


// Forms
$custom-control-indicator-checked-border-color: $primary-2;
$custom-control-indicator-checked-bg: $primary-2;

// Tables
$table-cell-padding-sm: 0.8rem;

// Variables isupplier
//$color-isupplier: $primary;
//$color-blue: $info;
//$color-dark: $color-base-1;
$color-dark-blue: $dark-gray;
//$color-title: $color-base-1;
//$color-text: $color-base-2;
$color-dark-gray: $color-gray-1;
//$color-gray: $color-gray-2;
//$color-light-gray: $color-gray-3;
//$color-light-red: $color-red-1;
//$color-purple: $color-secondary-3;
//$color-border: $color-gray-2;
//$font-title: 'Montserrat', Arial, sans-serif;
//$font-text: 'Helvetica', Arial, sans-serif;
//$color-light-blue: $color-primary-3;
