@import "variables";

.table {
  width: 100%;
  border-collapse: collapse;

  &,
  thead > tr,
  thead {
    background-color: transparent;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }

  th,
  td {
    border: 0 !important;
    font-family: "Open sans", serif;
  }

  tr {
    border-bottom: 1px solid #ddd;
    background-color: white;

    th {
      background-color: $secondary;
      font-size: 12px;
      color: white;
      padding: 15px;
      font-family: "Montserrat", serif;

      &:first-child {
        border-top-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
      }
    }

    td {
      vertical-align: center !important;
      padding: 20px;
      color: $dark;
    }
  }

  tfoot {
    border-top: 1px solid $dark;
  }
}

.table.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}

.table {
  .thead-normal {
    th {
      user-select: none;
      color: #6e7878;
      font: normal normal bold 15px/32px Open Sans;
      background-color: $white;
      border-bottom: 1px solid #CFCFCF !important;
    }
  }
}

.table-material {
  background: $white;
  border-radius: 5px;
}

