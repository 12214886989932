@import "variables";

ol.breadcrumb {
  border-bottom: none;
  margin-bottom: 0;
  border: 0;

  &:before {
    margin-right: .3rem;
    font-size: .8rem;
    color: $primary-2;
  }

  li.breadcrumb-item {
    font-size: .8rem;
    color: $dark-gray;
    a{
      color: $primary-2;
    }
  }
}
