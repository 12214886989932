@import "variables";

div.alert {

  border: none;

  &.alert-primary {
    background-color: lighten($primary, 35);
    color: $primary;
  }

  &.alert-info {
    background-color: lighten($info, 40);
    color: $info;
  }

  &.alert-success {
    background-color: lighten($success, 40);
    color: $success;
  }

  &.alert-warning {
    background-color: lighten($warning, 40);
    color: $warning;
  }

  &.alert-danger {
    background-color: lighten($danger, 35);
    color: $danger;
  }

}
