@import "variables";

label {
  color: $gray;

  &.required:after {
    content: " *";
    color: $danger;
  }
}

.form-control {
  //border-color: $gray;
  background-color: $light-gray !important;

  &:focus {
    box-shadow: none;
    border-color: $dark-gray;
    background-color: white;
    color: $dark-gray;
  }

  &.ng-dirty.ng-invalid {
    border-color: $danger;
  }

  &.ng-dirty.ng-valid {
    border-color: $success;
  }
}

.form-control:disabled {
  color: $color-dark-gray;
  border: none;
}

.custom-control-input {
  user-select: none;
  &:checked ~ .custom-control-label {
    color: $dark-gray;
    font-weight: bold;
  }

  &:focus ~ .custom-control-label {
    &:before {
      box-shadow: none;
    }
  }

  .custom-control-label:before {
    border-color: $gray;
  }
}

ngx-intl-tel-input .form-control.ng-dirty.ng-valid {
  border-color: #2ECC71 !important;
}

ngx-intl-tel-input.ng-invalid.ng-dirty.ng-touched input {
  border-color: red !important;
}