@import "variables";

main.main {
  div.container-fluid {
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: "Montserrat", serif;
      font-weight: bold;
      font-size: 1.875rem;
      color: $ultra-dark-gray;
      position: relative;
    }

    .underline {
      margin-bottom: 1.5rem;

      &:after {
        content: " ";
        display: block;
        background: transparent
          linear-gradient(180deg, $primary-1 0%, $secondary-1 100%);
        height: 0.3rem;
        margin-top: 0.3rem;
        width: 4rem;
      }
    }

    .section-title {
      &:before {
        display: inline-block;
        content: "";
        border-top: 0.2rem solid $dark-gray;
        vertical-align: middle;
        width: 1.5rem;
        margin-right: 0.5rem;
        transform: translateY(-0.1rem);
      }
    }

    h2 {
      font-size: 1.25rem;
    }

    h3,
    h4,
    h5 {
      font-family: "Open sans", serif;
      font-size: 1.125rem;
    }

    h4 {
      font-size: 1rem;
    }

    h5 {
      font-size: 0.875rem;
    }

    h6 {
      font-size: 0.875rem;
      font-weight: normal;
    }

    p {
      color: $dark-gray;
      font-weight: normal;

      &.light-text {
        color: $gray;
      }
    }

    ul {
      li {
        color: $ultra-dark-gray;
        line-height: 1.25rem;

        &::marker {
          font-size: 1.25rem;
        }
      }

      &.light-text {
        li {
          color: $gray;
        }
      }
    }

    a {
      &:link,
      &:visited,
      &.dropdown-toggle:not([href]) {
        color: $primary-2;
        text-decoration: underline;
      }

      &:hover,
      &.dropdown-toggle:not([href]):hover {
        color: $gray !important;
        cursor: pointer;
        text-decoration: underline !important;
      }
    }

    ul.dropdown-menu {
      border: none;
      box-shadow: 0px 1px 15px $medium-gray;
      border-radius: 5px;

      li {
        a.dropdown-item {
          text-decoration: none;
          color: $gray;

          &:hover {
            text-decoration: none !important;
            color: $gray;
            background-color: $light-gray;
          }
        }
      }
    }
  }
}

.header {
  font: normal normal bold 30px/41px Open Sans;
  letter-spacing: 1.11px;
  color: $dark-gray;
}

.sub-header {
  text-align: left;
  font: normal normal normal 20px/27px Open Sans;
  letter-spacing: 0.74px;
  color: $dark-gray;
}

.word-break-all {
  word-break: break-all;
}

.missions-header {
  text-align: left;
  font: normal normal bold 30px/41px Open Sans;
  letter-spacing: 1.11px;
  color: $dark-gray;
}

.missions-header-border {
  background-color: #d06262;
  border: 2px solid #d06262;
  border-radius: 2px;
}

.mission-details-name {
  text-align: left;
  font: normal normal normal 15px/20px Open Sans;
  letter-spacing: 0.56px;
  color: $dark-gray;
}

.mission-details-email {
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0.37px;
  color: $dark-gray;
}

.mission-details-number {
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0.37px;
  color: $dark-gray;
}

.mission-side-pane-header {
  text-align: left;
  font: normal normal bold 22px/20px Open Sans;
  color: $dark-gray;
}

.mission-side-pane-card-title {
  text-align: left;
  font: normal normal bold 15px/14px Open Sans;
  color: #6e7878;
}

.text-highlight {
  color: $primary-2 !important;
}

.font-weight-strong {
  font: normal normal bold 18px/32px Open Sans;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
