@import "variables";

.badge-success {
  background-color: transparentize($success, 0.8);
  color: darken($success, 0%);
}

.badge-warning {
  background-color: transparentize($warning, 0.8);
  color: darken($warning, 0%);
}

.badge-danger {
  background-color: transparentize($danger, 0.8);
  color: darken($danger, 0%);
}

.badge-info {
  background-color: transparentize($info, 0.8);
  color: darken($info, 0%);
}

.badge-primary {
  background-color: transparentize($primary, 0.8);
  color: darken($primary, 0%);
}

.badge-secondary {
  background-color: transparentize($secondary, 0.8);
  color: darken($secondary, 0%);
}

.badge-purple {
  background-color: transparentize($secondary-3, 0.8);
  color: darken($secondary-3, 0%);
}

.badge-gray{
  background-color: transparentize($color-gray-1, 0.8);
  color: darken($color-gray-1, 0%);
}

.badge-pink {
  background-color: transparentize(pink, 0.8);
  color: darken(pink, 0%);
}

.color-refused {
  background-color: #d5434340;
  color: darken(#d54343, 0%);
}

.color-submitted {
  background-color: transparentize(#4397b2, 0.8);
  color: darken($info, 0%);
}

.color-expired {
  background-color: transparentize(#af860a, 0.8);
  color: darken($warning, 0%);
}

.color-validated {
  background-color: transparentize(#258647, 0.8);
  color: darken($success, 0%);
}

.badge-darker-red{
  $badge-color: #ac0081;
  background-color: transparentize($badge-color, 0.8);
  color: darken($badge-color, 0%);
}

.badge-dark-blue {
  background-color: transparentize(  $color-dark-blue, 0.7);
  color: $color-dark-blue;
}
