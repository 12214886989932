.tab-label {
  border: none !important;
  color: #34495e !important;
  text-align: center;
  font: normal normal normal 18px/32px Open Sans;
}

.tab-label-error {
  border: none !important;
  color: #34495e !important;
  text-align: left;
  font: normal normal normal 18px/32px Open Sans;
}

.tab-label.active {
  color: #3498db !important;
  background: transparent !important;
  border-bottom: 3px solid #3498db !important;
  border-radius: 0 !important;
}

.tab-label-error.active {
  color: #e74c3c !important;
  background: transparent !important;
  border-bottom: 3px solid #e74c3c !important;
  border-radius: 0 !important;
}

.tab-link{
  cursor: pointer;
  display: block;
  padding: 0.2rem 1rem;
}
