@import "variables";

.box-shadow-hover {
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 0 3px 3px $medium-gray;
  }
}

.box {
  width: 100%;
  background: $white;
  border-radius: 5px;
}

.card-box {
  width: 100%;
  background: $white;
  border-radius: 5px;
  box-shadow: 0 3px 6px #00000029;
}

.box-red-border {
  width: 100%;
  height: 100%;
  background: #d543431a;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #d54343;
  border-radius: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.text-dark-gray {
  color: $color-gray-1 !important;
}

.text-dark-gray-hover {
  color: $color-gray-1 !important;

  &:hover {
    color: darken($color-gray-1, 20%);
  }
}

.btn-link {
  color: $primary-2;
  cursor: pointer;

  &:hover {
    color: darken($primary-2, 10%);
  }

  &:active {
    color: darken($primary-2, 15%);
  }
}

.text-purple {
  color: $secondary-3;
}

.text-burgundy {
  color: #7f2225;
}

.text-info {
  color: $info
}

.ellipse {
  height: 51px;
  width: 51px;
  border-radius: 50%;
  text-align: center;
}

.mat-icon-list {
  transform: scale(1.5) translateY(37%);
}

.ellipse-icon {
  height: 51px;
  width: 51px;
  border-radius: 50%;
  text-align: center;
}

.ellipse-icon-fa {
  height: 51px;
  width: 51px;
  border-radius: 50%;
  text-align: center;

  > i {
    color: #27e472;
    transform: translateY(10px);
  }
}

.list-content-header {
  text-align: left;
  font: normal normal normal 10px/12px Open Sans;
  color: #707070;
}

.list-content-subheader {
  text-align: left;
  font: normal normal bold 18px/18px Open Sans;
  color: #34495e;
}

.tooltip-rejected-reason{
  word-break: break-all !important;
  white-space: normal !important;
}

.max-hint {
  color: #95a5a6;
  letter-spacing: 0;
  font-size: 80%;
}

.modal-center {
  top: 25%;
}
